<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#F0F"/>
    <div v-show="items.length">
    <b-table-simple hover small caption-top responsive="xs">
      <b-thead head-variant="light">
        <b-tr>
          <b-th>{{$t('message.admbill_userid')}}</b-th>
          <b-th>{{$t('message.admbill_nickname')}}</b-th>
          <b-th>#</b-th>
          <b-th>{{$t('message.prepay_happen')}}</b-th>
          <b-th>{{$t('message.prepay_amount')}}</b-th>
          <b-th>{{$t('message.prepay_thisbalance')}}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in items" :key="index">
          <b-td>{{item.uid}}</b-td>
          <b-td>{{item.nickname}}</b-td>
          <b-td>{{item.payseq}}</b-td>
          <b-td>{{item.cretmstr}}</b-td>
          <b-td>{{item.amount}}</b-td>
          <b-td>{{item.thisbalance}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="gem"></b-icon> user prepay history</h4>
        </template>
        <b-card-text>
          <p>
            no data found<br/><br/>
           </p>
        </b-card-text>
      </b-card>
    </div>
    <b-form inline class="mt-4 ml-4" @submit.stop.prevent>
      <b-button-group class="mr-4 mb-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
      </b-button-group>
      <b-radio-group buttons v-model="payway" class="mr-4 mb-4" :options="payways" @change="uchange" button-variant="outline-primary"></b-radio-group>
      <b-input-group class="mr-4 mb-4">
        <b-form-input placeholder="nickname" v-model="searchnickname" @keyup.enter="search"></b-form-input>
        <b-input-group-append>
          <b-button variant="info" @click="search">{{$t('message.btn_search')}}</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')"><span v-html="modalmsg"></span></b-modal>
  </b-container>
</template>

<script>
  export default {
    name: 'admprepays',
    mounted() {
      this.payway = parseInt(localStorage.getItem('payway')) || 0;
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        workingid: -1,
        thefreemoney: 0,
        filteruid: this.$route.query.uid,
        modalshow:false,
        modalmsg: '',
        maskenabled: false,
        searchnickname:'',
        items: [],
        payway:0,
        payways: [{
          text: this.$t('message.prepay_cash'),
          value: 0
        }, {
          text: this.$t('message.prepay_paystack'),
          value: 1
        }, {
          text: this.$t('message.prepay_paypal'),
          value: 2
        }],
        pageid:0,
        newpagestart:[]
      };
    },
    methods: {
      uchange($event) {
        this.workingid = -1;
        this.searchmobile='';
        this.payway = $event;
        this.pageid = 0;
        this.newpagestart = [];
        localStorage.setItem('payway',''+$event);
        this.fetchData();
      },
      search() {
        this.pageid = 0;
        this.fetchData();
      },
      fetchprev() {
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchPage(page) {
        this.pageid = page;
        this.fetchData();
      },
      fetchNext() {
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      async fetchData() {
        this.isloading = true;
        let w = window.innerWidth;
        let h = window.innerHeight;
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&pagesize='+this.$pagesize+'&w='+w+'&h='+h+'&payway='+this.payway;
        if (this.filteruid) {
          theapi = theapi + '&uid='+this.filteruid;
        } else {
          theapi = theapi + '&search='+this.searchnickname;
        }
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          theapi = theapi + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        let axresp = await this.axios.post('/admprepays?tm='+new Date().getTime(), theapi);
        this.isloading = false;
        if (axresp.status==200) {
          let axdata = axresp.data;
          this.items = axdata.Items;
          if (axdata.LastEvaluatedKey) {
            if (this.newpagestart.length<=this.pageid) {
              this.newpagestart.push(axdata.LastEvaluatedKey);
            } else {
              this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
            }
          }
        } else {
          this.items = [];
        }
      },
      setme(index) {
        if ( this.workingid == index ) {
            this.workingid = -1;
        } else {
            this.workingid = index;
        }
      },
      async freemoney(itm) {
        this.workingid = -1;
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&uid='+itm.uid+'&money='+this.thefreemoney;
        let axresp = await this.axios.post('/admfreemoney?tm='+new Date().getTime(), theapi);
        if (axresp.status==200) {
          this.fetchData();
        }
      },
    }
  }
</script>
